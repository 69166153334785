import { Box, Button, Icon, Typography } from '@branch-messenger/willow-ui'
import { ChevronRight } from '@branch-messenger/willow-ui/icons'
import { useRouterState } from '@tanstack/react-router'
import { FC, useContext, useState } from 'react'

import { DialogContext } from '@/context'
import { useLogin, useSendEmailVerificationMutation } from '@/queries/auth'

import { LoginCodeVerificationForm } from '../Login/LoginCodeVerificationForm'

export const EmailVerification: FC = () => {
  const [attemptedResend, setAttemptedResend] = useState(false)
  const { mutate: sendVerificationEmail } = useSendEmailVerificationMutation()
  const { showAlert } = useContext(DialogContext)

  const routerState = useRouterState({
    select: state => state.location.state,
  })

  const { loginRequestBody } = routerState

  if (!loginRequestBody) return null

  const handleResendEmail = () => {
    sendVerificationEmail(loginRequestBody.email, {
      onSettled: () => {
        setAttemptedResend(true)
      },
    })
  }

  const handleEmailVerified = () => {
    !!loginRequestBody && login(loginRequestBody)
  }

  const handleEmailVerificationError = () => {
    showAlert({
      title: 'Verify your email',
      description:
        'It looks like your email still has not been verified. Please check your inbox to verify your email address or click the button below to resend verification email.',
      cancelDescription: 'Go back',
      actionDescription: 'Resend email',
      handleAction: handleResendEmail,
    })
  }

  const {
    mutate: login,
    isPending: loggingIn,
    data: loginData,
  } = useLogin(handleEmailVerificationError)

  if (loginData && loginRequestBody.password) {
    return (
      <LoginCodeVerificationForm
        loginData={{
          email: loginRequestBody.email,
          password: loginRequestBody.password,
        }}
        verificationChannel="EMAIL"
      />
    )
  }

  return (
    <Box>
      <Box $mb={3}>
        <Typography $size="3xl" $bold as="h1">
          Verify your email
        </Typography>
      </Box>
      <Box $mb={4}>
        <Typography>
          In order to login, your email address needs to be verified. Please
          check your inbox to verify your email address.
        </Typography>
      </Box>
      {!attemptedResend && (
        <Box>
          <Typography $color="textMuted">
            {"Didn't receive an email?"}
          </Typography>
          <Button size="lg" variant="link" onClick={handleResendEmail}>
            Resend email {'>'}
          </Button>
        </Box>
      )}
      {attemptedResend && (
        <Typography $color="textMuted">
          {`We will send a verification link if our records indicate that you have
          an account with us. If you still haven't received an email, please
          reach out to support `}
          <a
            target="_blank"
            href="https://support.branchapp.com/hc/en-us/requests/new"
            rel="noreferrer"
          >
            here
          </a>
          .
        </Typography>
      )}
      {!!loginRequestBody && (
        <Box $mt={4}>
          <Button
            loading={loggingIn}
            onClick={handleEmailVerified}
            size="lg"
            variant="link"
            iconRight={<Icon Icon={ChevronRight} />}
          >
            I verified, let me proceed
          </Button>
        </Box>
      )}
    </Box>
  )
}
