import '@fontsource-variable/reddit-sans'

import { GlobalStyle, WillowThemeProvider } from '@branch-messenger/willow-ui'
import { QueryClientProvider } from '@tanstack/react-query'
import React from 'react'
import ReactDOM from 'react-dom/client'

import { App } from './App.tsx'
import { AuthProvider } from './context/Auth.tsx'
import { queryClient } from './lib/query-client.ts'
import { initializeAnalytics } from './lib/sentry.ts'

initializeAnalytics()

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <WillowThemeProvider defaultTheme="light">
          <App />
          <GlobalStyle />
        </WillowThemeProvider>
      </AuthProvider>
    </QueryClientProvider>
  </React.StrictMode>
)
