import { useNavigate } from '@tanstack/react-router'
import { FC } from 'react'

import { Button } from '@/components/button'
import { Box } from '@/components/layout'
import { SVGIcon } from '@/components/svgIcon'
import { Description, Title, Typography } from '@/components/typography'
import { useOrg } from '@/selectors/useOrg'

export const AdditionalInfo: FC = () => {
  const navigate = useNavigate()
  const { orgInfo } = useOrg()
  // API call to get KYC persona status for user
  const kycPersonaSuccess = true
  const nextStep = () => {
    if (kycPersonaSuccess) {
      window.location.href = orgInfo?.id
        ? `${import.meta.env.VITE_ONBOARDING_URL}/?org=${orgInfo.id}`
        : `${import.meta.env.VITE_ONBOARDING_URL}/?org=100019`
    } else {
      navigate({ to: '/setup/identity-support' })
    }
  }

  return (
    <Box direction="column" align="start">
      <Box align="center" justify="center" css={{ mb: '$24' }}>
        <SVGIcon
          wrapperStyle={{ width: 'auto', marginRight: '24px' }}
          iconName="mobile_wallet"
        />
      </Box>
      <Title>We need to learn a little more about you.</Title>
      <Description css={{ mb: '$24' }}>
        We need to verify your identity to protect your account.
      </Description>
      <Typography bold>This is not a credit check.</Typography>
      <Button css={{ mt: '$40' }} onClick={nextStep}>
        Continue
      </Button>
    </Box>
  )
}
