import { Typography as WillowTypography } from '@branch-messenger/willow-ui'
import { styled as styledComponent } from 'styled-components'

import { css, styled } from '@/styles/stitches.config'

const sharedTypographyVariants = css({
  variants: {
    size: {
      xs: {
        fontSize: '$12',
      },
      sm: {
        fontSize: '$14',
      },
      md: {
        fontSize: '$16',
      },
      lg: {
        fontSize: '$18',
      },
      xl: {
        fontSize: '$24',
      },
      xxl: {
        fontSize: '$40',
      },
    },
    italicized: {
      true: {
        fontStyle: 'italic',
      },
    },
    underlined: {
      true: {
        textDecoration: 'underline',
      },
    },
    bold: {
      true: { fontWeight: '$bold' },
    },
    color: {
      primary: {
        color: '$brand',
      },
      secondary: {
        color: '$selection',
      },
      alert: {
        color: '$alert',
      },
      hint: {
        color: '$darkGray',
      },
      muted: {
        color: '$textMuted',
      },
    },
  },
})

export const Typography = styled('p', sharedTypographyVariants, {
  margin: 0,
})

export const Title = styled(Typography, {
  defaultVariants: {
    size: 'xxl',
    bold: true,
  },
})

export const WillowTitle = styledComponent(WillowTypography).attrs({
  $size: '3xl',
  $bold: true,
})`
  line-height: 1.0;  
`

export const Description = styled(Typography, {
  mt: '$16',
  color: '$textDark',
  lineHeight: '$20',

  defaultVariants: {
    size: 'sm',
  },
})

export const Span = styled('span', sharedTypographyVariants)
