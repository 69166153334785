import { PasswordValidity } from '@/components/password'

export const isValidPassword = (password: string) => {
  const passwordValidity: PasswordValidity = {
    charLength: password.length >= 8,
    hasLetter: /[a-zA-Z]/.test(password),
    hasDigit: /\d/.test(password),
    cases: /[a-z]/.test(password) && /[A-Z]/.test(password),
    special: /[!@#$%^&*(),.?":{}|<>]/.test(password),
  }

  let pwStrength = 0

  // Length factor
  if (password.length >= 14) pwStrength += 40
  else if (password.length >= 12) pwStrength += 30
  else if (password.length >= 10) pwStrength += 15

  // Check requirements
  if (passwordValidity.hasLetter) pwStrength += 10
  if (passwordValidity.hasDigit) pwStrength += 10
  if (passwordValidity.cases) pwStrength += 10
  if (passwordValidity.special) pwStrength += 10

  // Additional points for multiple occurrences
  const letterCount = (password.match(/[a-zA-Z]/g) || []).length
  const digitCount = (password.match(/\d/g) || []).length
  const specialCount = (password.match(/[!@#$%^&*(),.?":{}|<>]/g) || []).length
  const upperCount = (password.match(/[A-Z]/g) || []).length

  if (letterCount >= 2) pwStrength += 5
  if (digitCount >= 2) pwStrength += 5
  if (specialCount >= 2) pwStrength += 5
  if (upperCount >= 2) pwStrength += 5

  const isValid = Object.values(passwordValidity).every(Boolean)

  return { passwordValidity, isValid, pwStrength }
}
