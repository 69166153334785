import {
  Box,
  Button,
  Dropdown,
  DropdownRadioGroup,
  DropdownRadioItem,
} from '@branch-messenger/willow-ui'
import { ChevronSelectorVertical } from '@branch-messenger/willow-ui/icons'
import { useSuspenseQuery } from '@tanstack/react-query'
import { useMediaQuery } from '@uidotdev/usehooks'
import { FC, useState } from 'react'

import { useAuth } from '@/context/Auth'
import { useSwitchOrganization, workerInfoQueryOptions } from '@/queries/auth'

import { Spinner } from '../loader/styles'
import { StyledOrgProfileImage, StyledOrgProfileImageContainer } from './styles'

export const OrganizationSelector: FC = () => {
  const { orgId } = useAuth()
  const { data } = useSuspenseQuery(workerInfoQueryOptions)
  const isTablet = useMediaQuery('only screen and (max-width : 1024px)')
  const isMobile = useMediaQuery('only screen and (max-width : 480px)')
  const {
    mutate: switchOrganization,
    isPending,
    variables: switchingToOrgId,
  } = useSwitchOrganization()
  const [open, setOpen] = useState(false)

  if (data.length === 1) return null

  return (
    <Dropdown
      rootProps={{ open, onOpenChange: setOpen }}
      dropdownContentProps={{
        align: isMobile ? 'center' : isTablet ? 'end' : 'start',
        style: {
          minWidth:
            'min(var(--radix-dropdown-menu-content-available-width), 360px)',
        },
      }}
      showArrow={false}
      showClose
      trigger={
        <Button
          variant="pill"
          color="tertiary"
          iconRight={<ChevronSelectorVertical />}
        >
          Switch Organization
        </Button>
      }
      title="Select an Organization"
    >
      <DropdownRadioGroup
        value={orgId}
        onValueChange={async value => {
          switchOrganization(value, {
            onSettled: () => setOpen(false),
          })
        }}
      >
        {data.map(({ org_uuid, org_name, logo_url }) => (
          <DropdownRadioItem
            onSelect={e => e.preventDefault()}
            key={org_uuid}
            value={org_uuid}
          >
            <Box $display="flex" $gap={2}>
              <StyledOrgProfileImageContainer>
                <StyledOrgProfileImage src={logo_url} alt={org_name} />
              </StyledOrgProfileImageContainer>
              {org_name}
              {isPending && switchingToOrgId === org_uuid && (
                <Spinner size="xs" />
              )}
            </Box>
          </DropdownRadioItem>
        ))}
      </DropdownRadioGroup>
    </Dropdown>
  )
}
